.errorMessage
  color: red
  margin: 4px

.filesEdit
  display: flex
  flex-flow: row wrap
  width: 380px
  margin-left: 10px
  .file
    flex: 1 auto
    width: 180px
    height: 180px
    position: relative
    margin: 5px
    margin-bottom: 32px
    border-radius: 5px
    box-shadow: 2px 2px 5px #444
    .controls
      position: absolute
      bottom: 5px
      right: 5px
    .filePlaceholder
      display: block
      font-size: 40px
      text-align: center
      line-height: 180px
      border-radius: 5px
      color: #aaa
      text-shadow: -1px -1px 1px #555, 1px 1px 1px #ddd
      box-shadow: 2px 2px 1px #888
    .fileName
      position: absolute
      top: 100px
      font-size: 11px
      font-weight: bold
      color: #222
      width: 100%
      text-shadow: 0 0 3px #aaa

.fileProgress
  color: black
  font-size: 12px
  .reprogress
    display: none    // Anyway no transfer progress with Apollo

.dropzone
  border: 5px dashed gray
  border-radius: 16px
  height: 100%
  background-color: rgba(0, 0, 0, 0.3)
  &.changing
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    border-color: #d54
    border-radius: 5px
    .icon
      margin-top: -12px
      color: #d54
      text-shadow: 1px 1px 1px #222
      opacity: 0.6
  .icon
    display: block
    font-size: 64px
    color: gray
    line-height: 200px
    text-align: center
    width: 100%
  &.active
    border-style: solid
  &.accept
    border-color: green

// Images confirmation. Couldn't help myself
.pleaseDontDeleteMe
  margin: auto
  margin-top: 10px


