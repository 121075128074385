@use "sass:color"

@use '../../ui/base'
@use '../../ui/shop'

$sm-pic-side: 120px
$sm-info-height: 90px
$sm-name-size: 16px
$sm-name-padding: 2px 4px
$sm-icon-size: 20px
$sm-card-height: $sm-pic-side + $sm-info-height

$pic-side: 240px
$info-height: 60px
$name-size: 18px
$name-padding: 4px 8px
$icon-size: 70px
$card-height: $pic-side + $info-height

=name-outline($base-color)
  +base.outline($base-color, color.adjust($base-color, $lightness: 30%, $space: hsl))

=info-icon-colors($base-color)
  color: $base-color

=card-icon-size($side)
  width: $side
  text-align: center
  top: calc($side / 3)
  font-size: calc($side / 4)

=card-icon($dark, $bright, $side)
  +card-icon-size($side)
  +base.emboss($dark, $bright)
  
=plus-icon($side)
  +card-icon(#242, #cec, $side)
