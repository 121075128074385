.base
  perspective: 5000px
.appear, .enter
  opacity: 0
  transform: translate(0, 10px) rotateX(10deg)
.appearActive, .enterActive
  opacity: 1
  transform: translate(0, 0) rotateX(0)
  transition: all 0.3s ease-in-out
.exit
  opacity: 1
  transform: translate(0, 0) rotateX(0)
.exitActive
  opacity: 0
  transform: translate(0, -10) rotateX(-10deg)
  transition: 0.15s ease-in-out
