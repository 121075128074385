.RichEditor-styleButton {
  margin: 2px;
  padding: 4px;
  background-color: #eee;
  font-size: 8px;
}

.RichEditor-activeButton {
  border: 1px solid #ccc;
  border-radius: 2px;
}

ol.public-DraftStyleDefault-ol {
  counter-reset: item;
  list-style: none;
}

ol.public-DraftStyleDefault-ol li {
  margin-top: 30px;
  counter-increment: item;
  margin-bottom: 30px;
}

ol.public-DraftStyleDefault-ol li:before {
  margin-top: -30px;
  margin-right: 10px;
  font-size: 40px;
  font-style: italic;
  width: 40px;
  content: counter(item);
  color: gray;
}
