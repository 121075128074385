@use "sass:color"

@use '../../ui/shop'

$color: #ff7f50
$color-hi: color.adjust($color, $lightness: 10%, $space: hsl)

.nav
  background-color: $color
  &.selected
    background-color: $color-hi
    font-weight: 600
.add
  background-color: shop.$plus-color
