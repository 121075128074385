@use "sass:color"

@use 'shopViewConfig'

$color: #2a8bc6
$light-group-back: color.adjust($color, $lightness: -30%, $space: hsl)
$light-group-text: color.adjust($color, $lightness: 35%, $space: hsl)
$light-group-shadow: color.adjust($color, $lightness: 30%, $space: hsl)
$light-item-back: color.adjust($color, $lightness: 38%, $space: hsl)
$light-item-text: color.adjust($color, $lightness: -30%, $space: hsl)
$light-buy-back: color.adjust($color, $lightness: 30%, $space: hsl)
$light-buy-border: color.adjust($color, $lightness: 10%, $space: hsl)
$light-buy-text: color.adjust($color, $lightness: -20%, $space: hsl)
$light-sep: color.adjust($color, $lightness: 45%, $space: hsl)

$dark-group-back: color.adjust($color, $lightness: -25%, $space: hsl)
$dark-group-text: color.adjust($color, $lightness: 30%, $space: hsl)
$dark-group-shadow: color.adjust($color, $lightness: 15%, $space: hsl)
$dark-text: color.adjust($color, $lightness: 40%, $space: hsl)
$dark-item-back: color.adjust($color, $lightness: -35%, $space: hsl)
$dark-item-text: color.adjust($color, $lightness: 25%, $space: hsl)
$dark-buy-back: color.adjust($color, $lightness: -40%, $space: hsl)
$dark-buy-border: color.adjust($color, $lightness: -20%, $space: hsl)
$dark-buy-text: color.adjust($color, $lightness: 10%, $space: hsl)
$dark-sep: color.adjust($color, $lightness: -30%, $space: hsl)

.group
  border-radius: shopViewConfig.$border-rad
  margin-top: 16px
  margin-bottom: 4px
  box-shadow: 1px 1px 4px $light-group-shadow
  .header
    border-radius: shopViewConfig.$border-rad
    background-color: $light-group-back
    height: shopViewConfig.$header-height
    line-height: shopViewConfig.$header-height
    display: flex
    .name
      padding-left: shopViewConfig.$text-padding
      color: $light-group-text
      font-weight: bold
      flex: 1 auto
  .buys
    .item
      background-color: $light-item-back
      color: $light-item-text
      border-top: 1px solid $light-sep
      &:first-child
        border-top: none
      .ingredient
        display: flex
        align-items: center
        .buyButton
          color: $light-buy-text
          background-color: $light-buy-back
          border-color: $light-buy-border
          margin: 1px
        .ingredientName
          flex: 3 auto
          padding-left: shopViewConfig.$text-padding
          font-size: 16px
          flex-grow: 4
        .amounts
          flex: 1 auto
          display: flex
          justify-content: flex-end
          margin-right: 4px
          .amount
            margin-right: 6px
            font-size: 16px
            line-height: 28px
          .amount-group
            margin: 0px 4px
        .buttons
          flex: 0 auto
          display: flex
          margin-right: shopViewConfig.$text-padding
          .postponeButton
            color: #207010
          .button
            flex: 1 100%
            margin-left: 4px

:global(.dark) .group
  box-shadow: -1px -1px 4px $dark-group-shadow
  color: $dark-group-text
  .header
    background-color: $dark-group-back
    .name
      color: $dark-group-text
  .buys .item
    background-color: $dark-item-back
    color: $dark-text
    border-top: 1px solid $dark-sep
    .ingredient .buyButton
      color: $dark-buy-text
      background-color: $dark-buy-back
      border-color: $dark-buy-border
    .buttons
      .postponeButton
        color: #90c040

.result
  // height: 33px
  margin-right: 4px

.ghosted
  opacity: 0.5

.base
  perspective: 400px
.appear, .enter
  opacity: 0
  transform: translate(0, 10px) rotateX(10deg)
  margin-bottom: -(shopViewConfig.$element-height)
  transition: 0.5s ease-in-out
.appearActive, .enterActive
  opacity: 1
  transform: translate(0, 0) rotateX(0)
  margin-bottom: 0px
.enterDone
  transform: none
.exit
  opacity: 1
  transform: translate(0px, 0px) scale(1, 1) rotateX(0)
  transition: 0.3s ease-in-out
.exitActive
  opacity: 0
  transform: translate(10px, 0px) scale(0.9, 0.9) rotateX(-10deg)
  margin-bottom: -(shopViewConfig.$element-height)
