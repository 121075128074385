.list
  display: flex
  flex-direction: column
  flex-wrap: nowrap
  .title
    font-size: 16px
    font-weight: 600
  .sepTitle
    margin-top: 8px
  .section
    .entry
      margin: none
      display: block

