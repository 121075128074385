@use "sass:color"

@use '../../ui/shop.sass'

$bg-color: #9ee
$fg-color: color.adjust($bg-color, $lightness: -50%, $space: hsl)

.dots
  width: 60px
  max-width: 60px
  background-color: $bg-color
  color: $fg-color
