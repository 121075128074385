@use "sass:color"

@use '../../ui/shop.sass'

$group-color: #b92
$group-dark: color.adjust($group-color, $lightness: -30%, $space: hsl)
$group-light: color.adjust($group-color, $lightness: 30%, $space: hsl)
$group-lighter: color.adjust($group-color, $lightness: 40%, $space: hsl)

$header-height: 46px

.groups
  .group
    .bar
      min-height: $header-height
      line-height: $header-height
      border-radius: 4px
      padding-left: 20px
      margin-top: 4px
    .title
      background-color: $group-dark
      color: $group-lighter
    .add
      background-color: var(--plus-color-back)
      .name
        margin-right: 8px
      .icon
        color: var(--plus-color-icon)
      &.selected
        background-color: var(--plus-color-selected)
    .edit
      display: inline-block
      width: 32px
      height: 32px
      line-height: 32px
      margin-top: 7px
      text-align: center
      margin-right: 8px
      background-color: $group-lighter
      color: $group-dark
      border-radius: 4px
      float: right
      cursor: pointer
