@use "sass:color"

@use 'shopViewConfig'

$color: #8bc62a
$dark: color.adjust($color, $lightness: -30%, $space: hsl)
$darker: color.adjust($color, $lightness: -50%, $space: hsl)
$light: color.adjust($color, $lightness: 30%, $space: hsl)
$lighter: color.adjust($color, $lightness: 45%, $space: hsl)
$sep: color.adjust($color, $lightness: 25%, $space: hsl)

.group
  margin-top: 10px
  margin-bottom: 4px
  .header
    background-color: $dark
    color: $light
    height: shopViewConfig.$header-height
    line-height: shopViewConfig.$header-height
    display: flex
    padding-left: shopViewConfig.$text-padding
    flex: 1 auto
    .text
      margin-left: 4px
      color: $lighter
    .folded
      margin-left: 8px
      line-height: shopViewConfig.$header-height
      opacity: 0.5
  .recent
    .item
      background-color: $light
      cursor: pointer
      border-top: 1px solid $sep
      height: shopViewConfig.$element-height
      line-height: shopViewConfig.$element-height
      padding-left: 12px
      display: flex
      flex-flow: row nowrap
      .date
        opacity: 0.7
      .ingredient
        padding-left: 8px
