@use "sass:color"

$color: #8ae8d1

$img-css-width: 520px
$img-css-height: 330px

$padding: 48px

.pitch
  display: flex
  flex-flow: row wrap
  justify-content: center
  .container
    flex: 0 auto
    background-color: $color
    padding: $padding $padding
    margin: 32px
    color: color.adjust($color, $lightness: -50%, $space: hsl)
    border-radius: 32px
    max-width: $img-css-width + $padding * 2
    font-size: 14px
    box-shadow: 0px 0px 32px 0px color.adjust($color, $lightness: -30%, $space: hsl)
    .title
      margin-bottom: 32px
      text-align: center
    .image
      border-radius: 8px
      background-repeat: no-repeat
    .meals
      background-image: url('../../public/welcome/meals.png')
      background-size: contain
      width: $img-css-width
      height: $img-css-height
    .meal
      background-image: url('../../public/welcome/meal.png')
      background-size: contain
      width: $img-css-width
      height: $img-css-height
    .shop
      background-image: url('../../public/welcome/shop.png')
      background-size: contain
      width: $img-css-width
      height: $img-css-height
    p
      margin-top: 16px
