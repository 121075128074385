@use "sass:color"

$meal-color: #866
$ingredient-color: #994
$plus-color: #686
$group-color: #688

@function adjust-hsl($color, $lightness: null)
  @return color.adjust($color, $lightness: $lightness, $space: hsl)

// TODO: Convert those to use the new system
$meal-color-dark: adjust-hsl($meal-color, $lightness: -30%)
$ingredient-color-dark: adjust-hsl($ingredient-color, $lightness: -15%)
$plus-color-dark: adjust-hsl($plus-color, $lightness: -15%)
$group-color-dark: adjust-hsl($group-color, $lightness: -30%)

$meal-color-light: adjust-hsl($meal-color, $lightness: 30%)
$ingredient-color-light: adjust-hsl($ingredient-color, $lightness: 30%)
$plus-color-light: adjust-hsl($plus-color, $lightness: 40%)
$group-color-light: adjust-hsl($group-color, $lightness: 30%)
