@use "sass:color"

@use 'crud'
@use "../../ui/base"
@use "../../ui/shop"

.container
  flex-item: 1 auto
  margin: 12px
  &.normal
    width: crud.$pic-side
  &.small
    width: crud.$sm-pic-side

.contents
  background-color: #eee
  border-radius: 6px
  box-shadow: 1px 1px 4px #888
  position: relative
  &.normal
    height: crud.$card-height
  &.small
    height: crud.$sm-card-height

:global(.dark) .contents
  background-color: #444
  box-shadow: -1px -1px 4px #666
  color: #ddd

.picture
  position: absolute
  top: 0
  left: 0
  background-size: crud.$pic-side crud.$pic-side
  border-radius: 5px
  cursor: pointer
  box-shadow: 1px 0px 8px #222
  &.normal
    width: crud.$pic-side
    height: crud.$pic-side
  &.small
    width: crud.$sm-pic-side
    height: crud.$sm-pic-side
  &.ingredient
    background-color: var(--ingredient-color-back)
  &.meal
    background-color: var(--meal-color-back)
.name
  position: absolute
  font-family: Rubik
  text-shadow: 0 0 4px #222
  text-align: center
  width: 100%
  top: 10px
  font-weight: 500
  &.normal
    font-size: crud.$name-size
    padding: crud.$name-padding
    line-height: crud.$name-size
  &.small
    font-size: crud.$sm-name-size
    padding: crud.$sm-name-padding
    line-height: crud.$sm-name-size
  &.plus
    +base.outline(shop.$plus-color-dark, shop.$plus-color-light)
  &.meal
    +base.outline(shop.$meal-color-dark, shop.$meal-color-light)
    .match
      background-color: color.adjust(shop.$meal-color-dark, $lightness: 30%, $space: hsl)
  &.ingredient
    +base.outline(shop.$ingredient-color-dark, shop.$ingredient-color-light)
    .match
      background-color: color.adjust(shop.$ingredient-color-dark, $lightness: 30%, $space: hsl)
.info
  position: absolute
  left: 0
  font-size: 12
  padding: 4px
  &.normal
    top: crud.$pic-side
  &.small
    top: crud.$sm-pic-side
.infoTable
  display: flex
  flex-flow: column wrap
  font-size: 11px
  margin-top: 4px
.infoEntry
  flex-item: 1 auto
  margin-left: 6px
  white-space: nowrap
  line-height: 18px
  &.normal
    width: calc(crud.$pic-side / 2)
  &.small
    width: calc(crud.$sm-pic-side / 2)
.infoLabel
  margin-left: 6px
  color: #444
:global(.dark) .infoLabel
  color: #ccc

.infoData
  margin-left: 6px
  color: #000
:global(.dark) .infoData
  color: #fff

.infoIcon
  font-size: 9px
  &.plus
    +crud.info-icon-colors(shop.$plus-color)
  &.meal
    +crud.info-icon-colors(shop.$meal-color)
  &.ingredient
    +crud.info-icon-colors(shop.$ingredient-color)
.dropdown
  position: absolute
  right: 6px
  bottom: 6px
.plusCard
  position: absolute
  display: block
  background-color: var(--plus-color-back)
  border-radius: 6px
  cursor: pointer
  &.normal
    width: crud.$pic-side
    height: crud.$card-height
  &.small
    width: crud.$sm-pic-side
    height: crud.$sm-card-height
.plusIcon
  display: block
  color: var(--plus-color-icon)
  position: absolute
  &.normal
    +crud.plus-icon(crud.$pic-side)
    top: 90px
  &.small
    +crud.plus-icon(crud.$sm-pic-side)
    top: 60px
.plusName
  width: 100%
  color: shop.$plus-color-light
  font-family: Lato
  font-weight: normal
  position: absolute
  text-align: center
  color: shop.$plus-color-light
  font-size: 18px
  &.normal
    top: 170px
  &.small
    top: calc(crud.$sm-card-height / 2)
.modelIcon
  position: absolute
  top: -15px
  &.normal
    +crud.card-icon-size(crud.$pic-side)
  &.small
    +crud.card-icon-size(crud.$sm-pic-side)
  &.meal
    +base.emboss(#999, shop.$meal-color-dark)
    color: shop.$meal-color
  &.ingredient
    +base.emboss(#999, shop.$ingredient-color-dark)
    color: shop.$ingredient-color
