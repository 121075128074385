/* rubik-500 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: url('../../public/rubik-v9-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Rubik Medium'), local('Rubik-Medium'),
       url('../../public/rubik-v9-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../public/rubik-v9-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../public/rubik-v9-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../../public/rubik-v9-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../public/rubik-v9-latin-500.svg#Rubik') format('svg'); /* Legacy iOS */
}
