@use "sass:color"

$added-color-bg: #cfc
$removed-color-bg: #fcc

$added-color-fg: color.adjust($added-color-bg, $lightness: -70%, $space: hsl)
$removed-color-fg: color.adjust($removed-color-bg, $lightness: -70%, $space: hsl)

.table
  width: 100%
  .tr
    border-radius: 2px
  .added
    background-color: $added-color-bg
    color: $added-color-fg
  .removed
    background-color: $removed-color-bg
    color: $removed-color-fg
