/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('../../public/lato-v16-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Lato Light'), local('Lato-Light'),
       url('../../public/lato-v16-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../public/lato-v16-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../public/lato-v16-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../../public/lato-v16-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../public/lato-v16-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../../public/lato-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Lato Regular'), local('Lato-Regular'),
       url('../../public/lato-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../public/lato-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../public/lato-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../../public/lato-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../public/lato-v16-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('../../public/lato-v16-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Lato Italic'), local('Lato-Italic'),
       url('../../public/lato-v16-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../public/lato-v16-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../public/lato-v16-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../../public/lato-v16-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../public/lato-v16-latin-italic.svg#Lato') format('svg'); /* Legacy iOS */
}
