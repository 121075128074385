.mealIngredients
  display: flex
  flex-direction: column
  padding-left: 0px
  .mealPicIngredients
    display: flex
    flex-flow: row wrap
    .mealShow
      flex: 1 auto
  .mealNotes
    font-size: 12px
    margin-bottom: 8px
    .memoLine
      margin-bottom: 6px
  table
    margin-bottom: 0px
    td.amount
      white-space: nowrap
      text-align: right
