.base
  perspective: 5000px
  width: 100%
  
.appear, .enter
  .posAbs
    opacity: 0
    transform: translate(0, 10px) rotateX(10deg)
.appearActive, .enterActive
  .posAbs
    opacity: 1
    transform: translate(0, 0) rotateX(0)
    transition: 500ms ease-in-out
.exit .posAbs
  opacity: 1
  transform: translate(0, 0) rotateX(0)
.exitActive .posAbs
  opacity: 0
  transform: translate(0, -10) rotateX(-10deg)
  transition: 300ms ease-in-out

.posRel
  position: relative
  perspective: 5000px
  .posAbs
    position: absolute
    left: 0px
    top: 0px
    bottom: 0px
    right: 0px
    user-select: none
    .posNormal
      position: static
