\:global
  .app
    font-family: 'Lato', sans-serif
    background-color: #f8f8f8
    color: #202020
    &.dark
      background-color: #082020
      color: #f8f8f8
  a
    color: inherit
    text-decoration: inherit
    cursor: pointer
    &:hover
      color: inherit
  ul.nav
    align-items: center
