@use "sass:color"
@use './shop'

:root
  --plus-color: shop.$plus-color
  --group-color: shop.$group-color

  --highlight-background: #4caccc
  --highlight-color: #f8ffff

  --meal-color: shop.$meal-color
  --meal-color-back: #{shop.adjust-hsl(shop.$meal-color, $lightness: -30%)}
  --meal-color-text: #{shop.adjust-hsl(shop.$meal-color, $lightness: 30%)}
  --meal-color-match: #{shop.adjust-hsl(shop.$meal-color, $lightness: -40%)}
  --meal-color-match-back: #{shop.adjust-hsl(shop.$meal-color, $lightness: 20%)}
  --meal-color-icon: #{shop.adjust-hsl(shop.$meal-color, $lightness: -15%)}
  --meal-color-selected: #{shop.adjust-hsl(shop.$meal-color, $lightness: 40%)}

  --group-color-back: #{shop.adjust-hsl(shop.$group-color, $lightness: 30%)}
  --group-color-icon: #{shop.adjust-hsl(shop.$group-color, $lightness: -15%)}
  --group-color-text: #{shop.adjust-hsl(shop.$group-color, $lightness: -25%)}
  --group-color-selected: #{shop.adjust-hsl(shop.$group-color, $lightness: 40%)}
  --group-color-badge: #{shop.adjust-hsl(shop.$group-color, $lightness: 10%)}

  --ingredient-color: shop.$ingredient-color
  --ingredient-color-back: #{shop.adjust-hsl(shop.$ingredient-color, $lightness: -20%)}
  --ingredient-color-text: #{shop.adjust-hsl(shop.$ingredient-color, $lightness: -30%)}
  --ingredient-color-match: #{shop.adjust-hsl(shop.$ingredient-color, $lightness: 10%)}
  --ingredient-color-match-back: #{shop.adjust-hsl(shop.$ingredient-color, $lightness: -30%)}
  --ingredient-color-icon: #{shop.adjust-hsl(shop.$ingredient-color, $lightness: -15%)}
  --ingredient-color-selected: #{shop.adjust-hsl(shop.$ingredient-color, $lightness: 30%)}

  --plus-color-back: #{shop.adjust-hsl(shop.$plus-color, $lightness: -10%)}
  --plus-color-icon: #{shop.adjust-hsl(shop.$plus-color, $lightness: 5%)}
  --plus-color-selected: #{shop.adjust-hsl(shop.$plus-color, $lightness: 25%)}

  --tag-color-match: #101060
  --clickable-height: 48px

:global(.dark)
  --meal-color: #{shop.adjust-hsl(shop.$meal-color, $lightness: -30%)}
  --plus-color: #{shop.adjust-hsl(shop.$plus-color, $lightness: -15%)}
  --group-color: #{shop.adjust-hsl(shop.$group-color, $lightness: -30%)}

  --meal-color-back: #{shop.adjust-hsl(shop.$meal-color, $lightness: -30%)}
  --meal-color-text: #{shop.adjust-hsl(shop.$meal-color, $lightness: 20%)}
  --meal-color-match: #{shop.adjust-hsl(shop.$meal-color, $lightness: -40%)}
  --meal-color-match-back: #{shop.adjust-hsl(shop.$meal-color, $lightness: 20%)}
  --meal-color-icon: #{shop.adjust-hsl(shop.$meal-color, $lightness: -20%)}
  --meal-color-selected: #{shop.adjust-hsl(shop.$meal-color, $lightness: -20%)}

  --ingredient-color: #{shop.adjust-hsl(shop.$ingredient-color, $lightness: 20%)}
  --ingredient-color-text: #{shop.adjust-hsl(shop.$ingredient-color, $lightness: 40%)}
  --ingredient-color-back: #{shop.adjust-hsl(shop.$ingredient-color, $lightness: -25%)}
  --ingredient-color-match: #{shop.adjust-hsl(shop.$ingredient-color, $lightness: -40%)}
  --ingredient-color-match-back: #{shop.adjust-hsl(shop.$ingredient-color, $lightness: 10%)}
  --ingredient-color-icon: #{shop.adjust-hsl(shop.$ingredient-color, $lightness: -10%)}
  --ingredient-color-selected: #{shop.adjust-hsl(shop.$ingredient-color, $lightness: -20%)}

  --plus-color-back: #{shop.adjust-hsl(shop.$plus-color, $lightness: -15%)}
  --plus-color-icon: #{shop.adjust-hsl(shop.$plus-color, $lightness: 15%)}
  --plus-color-selected: #{shop.adjust-hsl(shop.$plus-color, $lightness: -15%)}

  --group-color-back: #{shop.adjust-hsl(shop.$group-color, $lightness: -30%)}
  --group-color-text: #{shop.adjust-hsl(shop.$group-color, $lightness: 15%)}
  --group-color-icon: #{shop.adjust-hsl(shop.$group-color, $lightness: 0%)}
  --group-color-selected: #{shop.adjust-hsl(shop.$group-color, $lightness: -20%)}
  --group-color-badge: #{shop.adjust-hsl(shop.$group-color, $lightness: -10%)}

  --highlight-background: #242

=nav
  display: block
  text-decoration: none
