@use "sass:color"

@use '../../ui/shop'

.meal
  background-color: var(--meal-color-back)
  color: var(--meal-color-text)
  .icon
    color: var(--meal-color-icon)
  &.selected
    background-color: var(--meal-color-selected)
.add
  background-color: var(--plus-color-back)
  .name
    margin-right: 8px
  .icon
    color: var(--plus-color-icon)
  &.selected
    background-color: var(--plus-color-selected)
.match
  color: var(--meal-color-match)
  background-color: var(--meal-color-match-back)
