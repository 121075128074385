.diet
  width: 100%
  .header
    min-height: calc(var(--clickable-height - 2px))
    background-color: #ccc
    border-radius: 8px
    margin-bottom: 4px
    display: flex
    align-items: center
    padding: 2px 4px
    .name
      flex: 2 auto
      font-weight: 700
      font-size: 24px
      color: #000
      padding-left: 12px
      line-height: var(--clickable-height)
    .controls
      flex: 1 auto
      text-align: right
      margin-right: 8px
  .meals
    display: flex
    flex-flow: row wrap
    .meal
      flex: 1 auto
