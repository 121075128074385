.micro
  color: #333
  background-color: #f0f0f0
  padding: 12px 8px
  margin: 4px
  border-radius: 8px
  .title
    font-weight: 700
    font-size: 22px
    line-height: 22px
    margin-bottom: 8px
  .memo
    font-size: 10px
    font-style: italic
  .ingredients
    display: flex
    flex-flow: row wrap
    margin-bottom: 8px
    .ingredient
      font-size: 10px
      line-height: 19px
      flex: 1 auto
      height: 24px
      padding: 2px 4px
      background-color: #ddd
      border-radius: 4px
      text-align: center
      margin: 2px
  .controls
    display: flex
    padding: 4px
    button
      flex: 1 auto
