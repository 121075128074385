.resultIngredient
  padding: 0px 4px
  background-color: rgba(0, 0, 0, 0.4)
  border-radius: 4px
  display: inline-block
  font-size: 12px
  margin-left: 8px
  .icon
    display: inline
    color: rgba(0, 0, 0, 0.5)
    font-size: 14px
    top: 0px
  .amount
    display: inline
    color: rgba(255, 255, 255, 0.7)
    font-weight: bold

.ingredientSelected
  border-color: #30c030

