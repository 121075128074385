$header-height: 48px
$height: 34px
$select-padding: 6px
$select-total-height: $height + $select-padding
$error-color: #711

@mixin input-common
  flex: 0 auto
  border-radius: 4px
  padding: 0px 8px
  font-size: 16px
  width: 100%
  &.errored
    border: 1px solid $error-color

@mixin input-base
  @include input-common
  border: 1px solid #a0a0a0
  box-shadow: inset 1px 1px 3px 0px rgba(20,20,20,0.3), 0px 0px 4px 0px rgba(20, 20, 20, 0.3)
  background-color: #f0f0f0

@mixin input-dark
  @include input-common
  border: 1px solid #606060
  box-shadow: inset 1px 1px 4px 0px rgba(40,40,40,1), 0px 0px 4px 0px rgba(200, 200, 200, 0.2)
  background-color: #383838
  color: #d0d0d0
  &.standard
    min-height: $height
  &:focus
    border: 2px solid #808080
    outline: green
  &:focus-visible
    border: 2px solid #808080
  &:placeholder
    color: red

.panel
  display: block
  margin-bottom: 8px
  border: 1px solid #c0c0c0
  border-radius: 8px
  overflow: hidden
  .title
    background-image: linear-gradient(#f0f0f0, #e0e0e0)
    border-bottom: 2px solid #a0a0a0
    color: #202020
    min-height: $header-height
    line-height: $header-height
    padding-left: 8px
  .content
    background-image: linear-gradient(#f8f8f8, #e8e8e8)
  .contentMargin
    padding: 8px

:global(.dark) .panel
  border: 1px solid #404040
  .title
    background-image: linear-gradient(#606060, #303030)
    color: #d0d0d0
    border-bottom: 2px solid #404040
  .content
    background-image: linear-gradient(#505050, #202020)

.inputBlockWrap
  display: flex
  flex-flow: column nowrap
  margin-bottom: 16px
  .label
    flex: 0 auto
    margin-bottom: .25em
.input
  height: $height
  @include input-base
  &.standard
    min-height: $height

:global(.dark)
  .input
    height: $height
    @include input-dark


.textArea
  @include input-base
:global(.dark) .textArea
  @include input-dark

.itemHeader
  display: inline-block

.button
  min-height: $height
  height: $height
  line-height: $height
  padding: 0px 8px
  border: 1px solid #b0b0b0
  background-image: linear-gradient(#f0f0f0, #c0c0c0)
  border-radius: 4px
  margin: 0 4px
  cursor: pointer

:global(.dark) .button
  background-image: linear-gradient(#505050, #303030)
  border: 1px solid #484848

.checkboxWrap
  margin-bottom: 16px

.radioGroupWrap
  margin-bottom: 16px

.checkboxLabel, .radioLabel
  margin-left: 8px

.inputPanelWrap
  display: flex
  flex-flow: row nowrap
  align-items: center

.error
  position: relative
  font-size: 12px
  color: $error-color
  padding: 4px
  .errorIcon
    width: calc(100% - 8px)
    position: absolute
    top: -26px 
    text-align: right
    color: $error-color
    pointer-events: none

.select
  height: $select-total-height - $select-padding
  padding: $select-padding
  border: 1px solid #b0b0b0
  background-image: linear-gradient(#ffffff, #f8f8f8, #e0e0e0)
  border-radius: 4px

:global(.dark) .select
  border: 1px solid #505050
  background-image: linear-gradient(#606060, #404040, #202020)
