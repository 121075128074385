@use "sass:color"

.pill
  height: var(--clickable-height)
  min-height: var(--clickable-height)
.ingredient
  background-color: var(--ingredient-color-back)
  color: var(--ingredient-color-text)
  white-space: nowrap
  .icon
    color: var(--ingredient-color-icon)
  &.selected
    background-color: var(--ingredient-color-selected)

.add
  background-color: var(--plus-color-back)
  .name
    margin-right: 8px
  .icon
    color: var(--plus-color-icon)
  &.selected
    background-color: var(--plus-color-selected)
.match
  color: var(--ingredient-color-match)
  background-color: var(--ingredient-color-match-back)
