// Requires data-text in the element you are outlining
=outline($outline-color, $text-color)
  &:before
    color: $text-color
    content: attr(data-text)
    position: absolute
    -webkit-text-stroke: 0.03em $outline-color
    left: 0
    top: 0
    width: 100%
    padding: inherit
    font-weight: 500

=emboss($dark, $bright)
  text-shadow: -1px -1px 3px $dark 1px 1px 1px $bright

