.wrapper
  margin-bottom: 16px
  display: flex
  flex-flow: row wrap
  .element
    flex: 1 auto
    margin: 2px
    padding: 10px
    height: var(--clickable-height)
    min-height: var(--clickable-height)
    line-height: 28px
    cursor: pointer
    border-radius: 4px
    box-shadow: 1px 1px 2px #888
    text-align: center
    .contents
      display: flex
      flex-flow: row wrap
      .contentItem
        flex: 1 auto

:global(.dark) .wrapper .element
  box-shadow: -1px -1px 2px #666
