@use "sass:color"

@use '../../ui/shop'

$text-padding: 4px

.element
  background-color: var(--group-color-back)
  color: var(--group-color-text)
  .name
    text-align: left
  .notes
    text-align: right
    padding-right: $text-padding + 1

.selected
  background-color: var(--group-color-selected)

.badge
  background-color: var(--group-color-badge)
