.comments
  width: 100%
  display: block
  font-size: 14px
  .inputTitle, .inputPost
    width: 100%
    display: block
    margin-bottom: 8px
  .buttonRighter
    width: 100%
    text-align: right
  .postLayout
    display: flex
    flex-flow: row nowrap
    .postVoting
      flex: 0 auto
      .votesLayout
        display: flex
        flex-flow: column nowrap
        .upvote
          font-weight: bold
        .points
          text-align: center
        .downvote
          font-weight: bold
    .postContent
      flex: 1 auto
      .postInfo
        width: 100%
        background-color: #a0a0a0
        padding: 8px
        display: flex
        flex-flow: row nowrap
        border-radius: 4px
        .infoUser
          flex: 0 auto
          padding: 8px
        .dates
          flex: 0 auto
          display: flex
          flex-flow: column nowrap
          .date
            flex: 0 auto
            font-size: 10px
            color: #505050
        .buttons
          margin-left: auto
          height: 100%
          .buttonSep
            margin-left: 4px
      .post
        padding: 8px
  .postReply
    flex: 0 auto

.noComments
  width: 100%
  text-align: center
  height: 48px
  line-height: 48px - 8 * 2
  font-size: 12px
  font-style: italic
  padding: 8px
  border: 2px dotted #a0a0a0
  border-radius: 4px
